<script>
import get from 'lodash/get';
import { mapActions, mapMutations, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CommentsList } from '@/components';
import { COMMENTABLE_TYPES } from '@/components/Comments/v1/constants/comments';

export default {
  name: 'UserCommentsView',
  components: {
    CommentsList,
  },
  props: {
    userUuid: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.comments, {
      comments: state => get(state, 'comments.data', []),
      commentsStatus: state => state.comments.STATUS,
    }),
  },
  created() {
    this.getCommentsByUuid();
  },
  methods: {
    async getCommentsByUuid() {
      this.clearData();
      await this.getCommentsByType({
        commentableUuid: this.userUuid,
        commentableType: COMMENTABLE_TYPES.user,
      });
    },
    ...mapActions(DOMAINS_MODEL.app.comments, [
      'getCommentsByType',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.comments, [
      'clearData',
    ]),
  },
};
</script>

<template>
  <div class="DetailsCommentsView">
    <CommentsList
      :comments="comments"
      :is-loading="commentsStatus.LOADING"
      :entity-uuid="userUuid"
      :callback="getCommentsByUuid"
      entity="user"
      class="mb-4"
    />
  </div>
</template>
